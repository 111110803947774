.slideToLeft {
  animation: slide-to-left 1s ease-in-out;
}
.slideToRight {
  animation: slide-to-right 1s ease-in-out;
}
@keyframes slide-to-left {
  0% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  49% {
    transform: translate(-40px, 0px);
    opacity: 0;
  }
  51% {
    transform: translate(40px, 0px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}
@keyframes slide-to-right {
  0% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
  49% {
    transform: translate(40px, 0px);
    opacity: 0;
  }
  51% {
    transform: translate(-40px, 0px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}
